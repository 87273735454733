<template>
    <div class="f-body">
        <div class="item">
            <h5><span class="title">函数: {{ showData.name }}</span></h5>
            <div class="info">
                {{showData.description}}
            </div>
        </div>
        <div class="item">
            <h5><span class="title">语法:</span></h5>
            <div class="info">
                {{showData.method||'无'}}
            </div>
        </div>
        <div class="item">
            <h5><span class="title">参数:</span></h5>
            <div class="args">
                <div v-if="showData&&showData.arguments.length" class="arg"  :key="item.arg+index" v-for="(item,index) in showData.arguments">
                    <div class="arg-title">{{ item.arg }}:</div>
                    <div class="arg-info" v-html="item.description"></div>
                </div>
                <div v-if="!showData.arguments||!showData.arguments.length"  class="arg">
                    无
                </div>
            </div>
        </div>
        <div class="item">
            <h5><span class="title">示例:</span></h5>
            <div class="examples">
                <table v-if="showData.examples&&showData.examples.length">
                    <tr :key="item.expression+index" v-for="(item,index) in showData.examples">
                        <td class="arg-title">{{ item.expression }}</td>
                        <td class="td-center">值</td>
                        <td class="arg-info">{{ item.returns }}</td>
                    </tr>
                </table>

                <div v-if="!showData.examples||!showData.examples.length" class="example">
                    无
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        detail: {
            type: Object,
            require: true,
        }
    },
    data() {
        return {}
    },
    computed: {
        showData: function () {
            return this.detail;
        }
    },
    methods: {}
}
</script>
<style scoped lang="less">
.f-body {
    height: 100%;
    .item {
        margin-bottom: 20px;
    }

    h5 {
        color: rgb(43, 125, 183);
        .title{
            padding: 5px 10px;
            display: inline-block;
        }
        background-color: #2d2d2d;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .info {
        font-size: 13px;
        line-height: 20px;
        color: #f7f7f7;
        padding-left: 10px;
        //margin-top: 10px;
    }
}

.args {
    font-size: 13px;
    line-height: 20px;
    color: #f7f7f7;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    //align-items: ;
    .arg {
        display: flex;
        padding: 6px 0;
        //margin-bottom: 15px;
        .arg-title {
            min-width: 85px;
            flex-shrink: 0;
            padding-right: 10px;
            color: #ce4747;
        }
        .arg-info{
            flex: 1;
        }
    }
}

.examples {
    font-size: 13px;
    line-height: 20px;
    color: #f7f7f7;
    padding-left: 10px;
    //background-color: #2d2d2d;
    display: flex;
    flex-direction: column;
    table{
        table-layout: auto;
        width: 100%;
        tr{
            td{
                border: 1px solid #2d2d2d;
                line-height: 32px;
            }
        }
    }
    //align-items: ;
    .arg-title {
        text-align: left;
        //white-space: nowrap;
        overflow: auto;
        width: 50%;
        //min-width: 120px;
        //flex-shrink: 0;
        //word-break: break-all;
        //padding-right: 30px;
        padding-right: 5px;
        color: #ce4747;
    }

    .td-center{
        text-align: center;
        width: 40px;
    }

    .arg-info{
        width: 60%;
        word-break: break-all;
        padding-left: 5px;
        text-align: left;
        //flex: 1;
    }
}

</style>